html, body {
    overflow-y: auto;
    color: #212529; }

.primary-background {
    background-color: #507ce8;
    color: #fff; }

.navbar-logo {
    max-width: 162px; }

.primary-header {
    background-color: transparent !important;

    .buttons {
        justify-content: center;

        .button {
            margin-left: 10px;
            margin-right: 10px; } } }

@include touch {
    .navbar-menu {
        background-color: transparent;
        box-shadow: none; }
    .navbar-item {
        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .button {
                width: 50%;
                margin-right: 0 !important; } } } }

.how-it-works {
    figure {
        box-shadow: 0 4px 8px 0 $grey, 0 6px 20px 0 $grey-dark;
        border-radius: 8px;

        img {
            border-radius: 8px; } }

    .feature-box {
        .subtitle {
            margin: 0.75rem 0; } }

    .feature-image {
        display: flex;
        justify-content: center;
        align-items: center;

        figure {
            flex-grow: 1; } }

    @include touch {
        .reverse-in-mobile {
            display: flex;
            flex-direction: column-reverse; } } }

.footer {
    padding: 3rem 1.5rem;

    .logo {
        width: 128px; }

    .bd-footer-link-title {
        color: $primary;
        font-size: 1.25rem;
        font-weight: 600;

        &:not(:first-child) {
            margin-top: 1.5rem; } }

    .bd-footer-link {
        margin-top: .5rem; }

    .bd-footer-links a {
        color: #5d648e !important;

        &:hover {
            color: #016FB9 !important; } }

    .bd-copyright {
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 1px solid $grey-lightest; }

    .company-data {
        > *:not(:first-child) {
            margin-top: .25rem; } } }

.brand-icon {
    display: flex;
    margin-bottom: 10px;

    &.is-centered {
        justify-content: space-around; } }

form > *:last-child {
    margin-top: 30px; }

.contact-header {
    padding-bottom: 16rem; }

#features {
    .box {
        .content {
            min-height: 200px; }

        &-image {
            display: flex;
            justify-content: center; } } }

.pricing .hero-body .columns {
    .floating-label {
        max-width: 230px;
        margin: 0 auto 20px; }

    .pricing-table {
        margin-bottom: 20px; }

    .invitation {
        padding-top: 120px;
        text-align: left; } }

@include mobile {
    .pricing {
        .invitation {
            padding: 0 0 30px;
            text-align: center; } } }

#navigation {
    background-color: #f5f7f9;
    color: $black-bis;

    #index & {
        & section.hero {
            background-image: url("/assets/images/bg.jpg");
            background-position: center;
            background-size: cover; }

        .hero-body .container {
            background-color: transparentize(#f5f7f9, 0.3); }

        .hero-body .container {
            border-radius: 6px;
            padding: 1.25rem 0; }

        @include desktop {
            .navbar-brand {
                border-bottom-left-radius: 6px; }
            .navbar-menu {
                border-bottom-right-radius: 6px; } } } }

.plan {
    -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    color: #212529; }

.plan-title {
    text-transform: uppercase;
    padding: 2.5rem 0;
    font-size: 1.3rem;
    text-align: center;
    border-bottom: 1px solid #f6f9fc;
    margin-bottom: 1.5rem;
    font-weight: 500; }

.plan-title--enterprise {
    color: #fff;
    border-bottom-color: rgba(85, 90, 191, .1); }

.plan-description {
    max-width: 460px;
    padding: 0 1.25rem 1rem;
    text-align: center;
    margin: 0 auto; }

.plan-description--enterprise {
    font-size: 1.2rem; }

.plan-description-subscription {
    max-width: 460px;
    padding: 0 2rem 1rem;
    text-align: center;
    margin: 0 auto;
    color: #aab7c4; }

.plan-price {
    font-size: 3rem;
    text-align: center; }

.plan-list {
    max-width: 300px;
    margin: 0 auto !important;
    padding: 3rem 0 3rem 3rem; }

.plan-list li {
    padding: .25rem 0; }

.icon i, .plan-list li i {
    text-align: center; }

.plan-price-description {
    font-size: .9rem;
    color: rgba(10, 10, 10, .4);
    text-align: center; }

.plan-button {
    background: #f6f9fc;
    display: block;
    color: #32325d;
    text-align: center;
    padding: 1.75rem;
    -webkit-transition: background .15s ease;
    transition: background .15s ease;
    text-decoration: none !important;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600; }

.plan-button:hover {
    color: #32325d;
    background: #e6ebf1; }

.plan-button--enterprise {
    background: rgba(85, 90, 191, .2);
    color: #87bbfd; }

.plan-button--enterprise:hover {
    background: rgba(85, 90, 191, .35);
    color: #87bbfd; }

.plan--standard {
    background: #fff; }

.plan--enterprise {
    color: #fff;
    background: #32325d;
    border-radius: 0 0 4px 4px; }

@media (min-width: 768px) {
    .plan--enterprise {
        border-radius: 0 4px 4px 0; } }
