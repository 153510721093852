.floating-label {
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  textarea {
    height: initial; }

  label {
    position: absolute;
    pointer-events: none;
    top: calc(50% - 7px);
    left: 0;
    opacity: 0;
    transition: all .3s ease;
    padding-left: 44px;
    font-size: 12px;
    color: #000;
    font-weight: 400; }

  textarea + label {
    top: 17px;
    @include mobile {
      top: 20px; } }

  select + label, textarea + label {
    font-size: 16px;
    @include mobile {
      font-size: 12px; } }

  input, textarea, select {
    width: calc(100% - 44px);
    max-width: calc(100% - 44px);
    min-width: calc(100% - 44px);
    margin-left: auto;
    display: flex;
    font-size: 16px;
    line-height: 16px;
    padding: 20px 0;
    height: 56px;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    box-sizing: border-box;
    transition: all .3s linear;
    color: #000;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus {
      border-bottom: solid 1px $primary;
      outline: 0;
      box-shadow: 0 2px 6px -8px #016FB9; }
    @include mobile {
      font-size: 12px; } }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 44px;
    display: flex;

    svg {
      height: 30px;
      width: 30px;
      margin: auto;
      opacity: .15;
      transition: all .3s ease;

      path {
        transition: all .3s ease; } } }
  select {
    &[value=""] + label {
      opacity: .6; }

    &:not([value=""]) {
      padding: 28px 0 12px 0;

      + label {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: .7; } }
    &:valid:not([value=""]) + label + .icon svg {
      opacity: 1;

      path {
        fill: $primary; } }

    &:not(:valid):not(:focus) + label + .icon {
      -webkit-animation-name: shake-shake;
      animation-name: shake-shake;
      -webkit-animation-duration: .3s;
      animation-duration: .3s; } }

  textarea {
    &:valid {
      padding: 28px 0 12px 0;
      + label {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: .7;
        font-size: 12px; } }

    &:not(:valid) + label {
      opacity: .5; }

    &:valid + label + .icon svg {
      opacity: 1;

      path {
        fill: $primary; } }

    &:not(:valid):not(:focus) + label + .icon {
      -webkit-animation-name: shake-shake;
      animation-name: shake-shake;
      -webkit-animation-duration: .3s;
      animation-duration: .3s; } }


  input {
    &:not(:placeholder-shown) {
      padding: 28px 0 12px 0;

      + label {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: .7; } }

    &:valid:not(:placeholder-shown) + label + .icon svg {
      opacity: 1;

      path {
        fill: $primary; } }

    &:not(:valid):not(:focus) + label + .icon {
      -webkit-animation-name: shake-shake;
      animation-name: shake-shake;
      -webkit-animation-duration: .3s;
      animation-duration: .3s; } } }

@-webkit-keyframes shake-shake {
  0% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  20% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px); }

  40% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  60% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px); }

  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }

@keyframes shake-shake {
  0% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  20% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px); }

  40% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  60% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px); }

  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px); }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }
